const IMAGES = {
  POINT_RIGHT: {
    mainSrc:
      'https://wordup-production-public.s3.ap-northeast-1.amazonaws.com/shop/assets/character_point_right.png',
    webp: 'https://wordup-production-public.s3.ap-northeast-1.amazonaws.com/shop/assets/character_point_right.webp',
  },
  WELL_DONE: {
    mainSrc: 'https://public.wordup.com.tw/shop/assets/well_done.png',
    webp: 'https://public.wordup.com.tw/shop/assets/well_done.webp',
  },
  PAGE_RESTRICTION: {
    mainSrc:
      'https://wordup-production-public.s3.ap-northeast-1.amazonaws.com/shop/assets/page_restriction.png',
    webp: 'https://wordup-production-public.s3.ap-northeast-1.amazonaws.com/shop/assets/page_restriction.webp',
  },
  NOT_FOUND: {
    mainSrc: 'https://public.wordup.com.tw/shop/assets/404_page_kv_D.png',
    webp: 'https://public.wordup.com.tw/shop/assets/404_page_kv_D.webp',
  },
  HAPPY_AVATAR: {
    mainSrc: 'https://public.wordup.com.tw/shop/assets/sign+up+page/dumpling_avatar_happy.png',
  },
  SIGN_UP: {
    mainSrc: 'https://public.wordup.com.tw/shop/assets/sign+up+page/sign_up_dumpling.png',
  },
  FLASHCARD_WARNING: {
    mainSrc: 'https://public.wordup.com.tw/shop/assets/flashcard_warning.png',
    webp: 'https://public.wordup.com.tw/shop/assets/flashcard_warning.webp',
  },
  NEW_FUNCTION: {
    mainSrc: 'https://public.wordup.com.tw/shop/assets/under_construction.png',
    webp: 'https://public.wordup.com.tw/shop/assets/under_construction.webp',
  },
  CONFUSED: {
    mainSrc: 'https://public.wordup.com.tw/empty_status/G4.png',
  },
  EMPTY: {
    mainSrc: 'https://public.wordup.com.tw/empty_status/G9.png',
  },
  WINKED: {
    mainSrc: 'https://public.wordup.com.tw/empty_status/G6.png',
  },
  NO_CONTENT_YET: {
    mainSrc: 'https://public.wordup.com.tw/empty_status/G1.png',
  },
}

export default IMAGES
