import { useState } from 'react'
import IMAGES from './constants'

export type SpaceDumplingVariantTypes = keyof typeof IMAGES

type ImageType = {
  mainSrc: string
  webp?: string
}

type SpaceDumplingImgProps = Omit<
  React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>,
  'src'
> & {
  variant: SpaceDumplingVariantTypes
}

const SpaceDumplingImg = (props: SpaceDumplingImgProps) => {
  const { variant, alt } = props
  const img = useState({
    mainSrc: IMAGES[variant].mainSrc,
    webp: 'webp' in IMAGES[variant] ? (IMAGES[variant] as ImageType)?.webp : undefined,
  })[0]

  return (
    <picture>
      {img.webp && <source srcSet={img.webp} type='image/webp' />}
      <img {...props} src={img.mainSrc} alt={alt} />
    </picture>
  )
}

export default SpaceDumplingImg
