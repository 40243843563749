import { isEmpty, omitBy } from 'lodash-es'
import path from 'path'
import { getFilteredQuery } from '@libs-components/utils/url'
import { AllPaths } from '@/routes/constants'
import { AppView, CommentFeedbackForType, MaterialComponentType } from '@/typings'
import ErrorNotifier from '@/utils/error-notifier'
import { appendQueryObject } from '@/utils/urls'
import { GetPracticeCategoriesParams } from '@/apis/student/material/types'

interface ToEditMaterialComponentProps {
  materialId: string
  componentId: string
  questionId?: string
  userName?: string
}

interface ToEditMaterialComponentCommentProps {
  materialId: string
  componentId: string
  from: string
  commentableId?: string
  feedbackFor?: CommentFeedbackForType | null
}

interface ToKlassSubpageProps {
  klassId: string
  subPageType: string
}

interface ToKlassMaterialProgressesProps {
  klassId: string
  materialId: string
}

type ToKlassAnalysisProps<Extra = {}> = {
  klassId: string
  materialId: string
  mcId: string
  questionGroupId?: string
  questionId?: string
} & Extra

interface ToKlassStudentAnalysisProps {
  klassId: string
  studentId: string
}

export const toExternalRoute = (url: string) => window.location.assign(url)

export const toHome = () => `${AllPaths.HOME}`

export const toDashboardKlass = () => `${AllPaths.DASHBOARD_CLASSES}`

export const toDashboardMaterial = () => `${AllPaths.DASHBOARD_MATERIALS}`

export const toDashboardProducts = () => `${AllPaths.DASHBOARD_PRODUCTS}`

export const toDashboardComments = () => `${AllPaths.DASHBOARD_COMMENTS}`

export const toMaterial = (materialId: string, moreParams?: string) =>
  `${AllPaths.MATERIALS}/${materialId}${moreParams ? `?${moreParams}` : ''}`

export const toMaterialSubscriptionDashboard = (materialId: string) =>
  `${AllPaths.MATERIALS}/${materialId}/subscription-dashboard`

export const toMaterialNotification = (materialId: string) =>
  `${AllPaths.DASHBOARD_MATERIALS}/${materialId}/notification`

export const toMaterialChapter = ({
  materialId,
  chapterId,
}: {
  materialId: string
  chapterId?: string
}) => `${AllPaths.MATERIALS}/${materialId}${chapterId ? `/chapter/${chapterId}` : ''}`

export const toManageMaterial = (materialId: string) =>
  `${AllPaths.DASHBOARD_MATERIALS}/${materialId}/content`

export const toEditMaterialComponent = ({
  materialId,
  componentId,
  questionId,
  userName,
}: ToEditMaterialComponentProps) => {
  return appendQueryObject(
    `${AllPaths.DASHBOARD_MATERIALS}/${materialId}/content/components/${componentId}`,
    {
      questionId,
      userName: userName ? encodeURIComponent(userName) : undefined,
    },
  )
}

export const toEditMaterialComponentComment = ({
  materialId,
  componentId,
  from,
  commentableId,
  feedbackFor,
}: ToEditMaterialComponentCommentProps) =>
  appendQueryObject(
    `${AllPaths.DASHBOARD_MATERIALS}/${materialId}/content/components/${componentId}`,
    {
      from,
      commentableId,
      feedbackFor,
    },
  )

export const toAddNewCard = ({
  materialId,
  componentId,
  mode,
}: {
  materialId: string
  componentId: string
  mode?: 'admin'
}) => ({
  pathname: `${AllPaths.DASHBOARD_MATERIALS}/${materialId}/content/components/${componentId}`,
  query: { cardId: 'create-new-card', mode },
})

export const toEditProduct = (productId: string, step?: string) =>
  `${AllPaths.DASHBOARD_PRODUCTS}/${productId}/edit/${step || 'basic-info'}`

export const toClass = ({ klassId }: { klassId: string }) =>
  `${AllPaths.DASHBOARD_CLASSES}/${klassId}`

export const toClassSubPage = ({ klassId, subPageType }: ToKlassSubpageProps) =>
  `${AllPaths.DASHBOARD_CLASSES}/${klassId}/${subPageType}/new`

export const toClassMaterialProgressesPage = ({
  klassId,
  materialId,
}: ToKlassMaterialProgressesProps) =>
  `${AllPaths.DASHBOARD_CLASSES}/${klassId}/materials/${materialId}/progresses`

export const toClassStudentStudyStatsPage = ({
  klassId,
  materialId,
}: ToKlassMaterialProgressesProps) =>
  `${AllPaths.DASHBOARD_CLASSES}/${klassId}/materials/${materialId}/student-study-stats`

export const toClassStudentStudyStatsFullTestsPage = ({
  klassId,
  materialId,
}: ToKlassMaterialProgressesProps) =>
  `${AllPaths.DASHBOARD_CLASSES}/${klassId}/materials/${materialId}/student-study-stats/full-tests`

export const toClassStudentStudyStatsTagPage = ({
  klassId,
  materialId,
}: ToKlassMaterialProgressesProps) =>
  `${AllPaths.DASHBOARD_CLASSES}/${klassId}/materials/${materialId}/student-study-stats/tag`

export const toClassStudentStudyStatsTagIdPage = ({
  klassId,
  materialId,
  tagId,
}: {
  klassId: string
  materialId: string
  tagId: string
}) =>
  `${AllPaths.DASHBOARD_CLASSES}/${klassId}/materials/${materialId}/student-study-stats/tag/${tagId}`

export const toClassDeckAnalysisPage = ({ klassId, materialId, mcId }: ToKlassAnalysisProps) => ({
  pathname: `${AllPaths.DASHBOARD_CLASSES}/${klassId}/materials/${materialId}/progresses/decks/${mcId}`,
})

export const toClassArticleAnalysisPage = ({
  klassId,
  materialId,
  mcId,
}: ToKlassAnalysisProps) => ({
  pathname: `${AllPaths.DASHBOARD_CLASSES}/${klassId}/materials/${materialId}/progresses/article/${mcId}`,
})

export const toClassVideoAnalysisPage = ({ klassId, materialId, mcId }: ToKlassAnalysisProps) => ({
  pathname: `${AllPaths.DASHBOARD_CLASSES}/${klassId}/materials/${materialId}/progresses/video/${mcId}`,
})

export const toClassTimedArticleAnalysisPage = ({
  klassId,
  materialId,
  mcId,
}: ToKlassAnalysisProps) => ({
  pathname: `${AllPaths.DASHBOARD_CLASSES}/${klassId}/materials/${materialId}/progresses/timed-article/${mcId}`,
})

export const toClassQuestionAnalysisPage = ({
  klassId,
  materialId,
  mcId,
  questionGroupId,
  quickView = false,
  questionId,
}: ToKlassAnalysisProps<Partial<{ quickView: boolean }>>) => ({
  pathname: `${AllPaths.DASHBOARD_CLASSES}/${klassId}/materials/${materialId}/progresses/questions/${questionGroupId}`,
  query: getFilteredQuery({ mcId, questionId, quickView }),
})

export const toClassStudentAnalysisPage = ({
  klassId,
  studentId,
}: ToKlassStudentAnalysisProps) => ({
  pathname: `${AllPaths.DASHBOARD_CLASSES}/${klassId}/students/${studentId}`,
})

export const toChapterSectionAnchor = ({
  sectionId,
  materialId,
  chapterId,
}: {
  sectionId: string
  materialId: string
  chapterId: string
}) => `${AllPaths.MATERIALS}/${materialId}/chapter/${chapterId}#${sectionId}`

export const toMaterialComponent = ({
  materialId,
  componentId,
  chapterId,
  materialType,
  mcId,
  otherParams,
}: {
  materialId: string
  componentId: string
  chapterId: string
  materialType: MaterialComponentType
  mcId?: string
  otherParams?: {
    [key: string]: string
  }
}) => {
  if (materialType === 'deck') {
    return {
      pathname: `${AllPaths.DECKS}/${mcId}`,
      query: omitBy(
        {
          deckId: mcId,
          materialId,
          componentId,
          chapterId,
          ...otherParams,
        },
        isEmpty,
      ),
    }
  } else {
    return {
      pathname: `${AllPaths.MATERIALS}/${materialId}/chapter/${chapterId}/components/${componentId}/${materialType}`,
      query: omitBy(
        {
          mcId,
          ...otherParams,
        },
        isEmpty,
      ),
    }
  }
}

export const toMaterialsUnUsedPage = () => {
  return `${AllPaths.MATERIALS}/unused`
}

export const toExamResultHistoryPage = ({
  examPaperId,
  ...restProps
}: {
  examPaperId: string
  materialId?: string
  chapterId?: string
  componentId?: string
  initQId?: string
  lastWrongOnly?: string | null
  wrongTimes?: string | null
  tags?: string | null
  examSections?: string | null
  from?: string | null
  doNotMixContext?: string | null
  levelId?: string | null
}) => {
  return {
    pathname: `${AllPaths.EXAM_PAPER}/${examPaperId}`,
    query: omitBy(
      {
        examPaperId,
        ...restProps,
      },
      isEmpty,
    ),
  }
}
export const toExamPage = ({
  mcId,
  materialId,
  chapterId,
  appView,
  from,
}: {
  mcId: string
  materialId?: string
  chapterId?: string
  appView?: AppView
  from?: 'subscription'
}) => {
  return {
    pathname: `${AllPaths.EXAM}/${mcId}`,
    query: omitBy(
      {
        mcId,
        materialId,
        chapterId,
        appView,
        from,
      },
      isEmpty,
    ),
  }
}

export const toStudentDecksResultHistoryPage = ({
  deckId,
  klassId,
  materialId,
  orgId,
}: {
  deckId: string
  materialId: string
  klassId: string
  orgId: string
}) => {
  return `${AllPaths.DASHBOARD_CLASSES}/${klassId}/materials/${materialId}/progresses/decks/${deckId}?orgId=${orgId}`
}

export const toStudentExamResultHistoryPage = ({
  examPaperId,
  materialId,
  orgId,
  actAsUserId,
}: {
  examPaperId: string
  materialId?: string
  orgId?: string
  actAsUserId?: string
}) => {
  const queryStrings = []
  if (materialId) {
    queryStrings.push(`materialId=${materialId}`)
  }
  if (orgId) {
    queryStrings.push(`orgId=${orgId}`)
  }
  if (actAsUserId) {
    queryStrings.push(`actAsUserId=${actAsUserId}`)
  }

  return `${AllPaths.EXAM_PAPER}/${examPaperId}${queryStrings ? `?${queryStrings.join('&')}` : ''}`
}

export const toShopSubscriptionPage = (subscriptionId: string) =>
  `${process.env.NEXT_PUBLIC_WU_SHOP_DOMAIN}/subscriptions/${subscriptionId}`

export const toShopProductPage = (materialId: string) =>
  `${process.env.NEXT_PUBLIC_WU_SHOP_DOMAIN}/product/${materialId}`

export const toShopProductPageWithUtm = ({
  productId,
  utmSource,
  utmMedium,
}: {
  productId: string
  utmSource: string
  utmMedium: string
}) =>
  `${process.env.NEXT_PUBLIC_WU_SHOP_DOMAIN}/product/${productId}?utm_source=${utmSource}&utm_medium=${utmMedium}`

export const toShopSearchPage = () => `${process.env.NEXT_PUBLIC_WU_SHOP_DOMAIN}/search`

export const toChapterAiQuestionsPage = ({ materialId }: { materialId: string }) => {
  return `/materials/${materialId}${AllPaths.INCORRECT_QUESTIONS}`
}

export const toChapterAiPracticePage = ({
  materialId,
  wrongTimes,
  lastWrongOnly,
  tags,
  examSections,
  appView,
  productId,
  from,
  doNotMixContext,
  levelId,
}: GetPracticeCategoriesParams & {
  appView?: AppView
  productId?: string
  from?: string | null
}) => {
  const query = {
    lastWrongOnly: String(lastWrongOnly),
    wrongTimes: wrongTimes !== undefined ? String(wrongTimes) : undefined,
    tags: tags ? tags.join(',') : undefined,
    examSections: examSections ? examSections.join(',') : undefined,
    productId,
    appView,
    from,
    doNotMixContext,
    levelId,
  }
  const url = `/materials/${materialId}${AllPaths.AI_PRACTICE}`

  return {
    pathname: url,
    query,
  }
}

export const toSearchedStrPage = ({
  deckId,
  searchedStr,
}: {
  deckId: string
  searchedStr: string
}) => {
  return `${AllPaths.DECKS}/query?currentDeckId=${deckId}&queryStr=${searchedStr}`
}

export const toDeckPage = ({
  deckId,
  materialId,
  chapterId,
  studyCard,
  from,
  componentId,
  scrollToCardId,
}: {
  deckId: string
  materialId?: string
  chapterId?: string
  studyCard?: string
  from?: string
  componentId?: string
  scrollToCardId?: string
}) => ({
  pathname: `${AllPaths.DECKS}/${deckId}`,
  query: omitBy(
    {
      deckId,
      materialId,
      chapterId,
      studyCard,
      from,
      componentId,
      scrollToCardId,
    },
    isEmpty,
  ),
})

export const toCardsEditPage = ({
  searchedWord,
  redirectUrl,
  deckId,
}: {
  searchedWord?: string
  deckId?: string
  redirectUrl: string
}) => {
  return {
    pathname: AllPaths.CARDS_EDIT,
    query: omitBy(
      {
        searchedWord,
        redirectUrl,
        deckId,
      },
      isEmpty,
    ),
  }
}

export const toSetCardPage = ({ deckId, cardId }: { deckId?: string; cardId?: string }) => {
  if (cardId) return `${AllPaths.DECK_CARDS}/edit?deckId=${deckId}&cardId=${cardId}`

  return `${AllPaths.DECK_CARDS}/edit?deckId=${deckId}&cardId=new`
}

export const toMoveCardsPage = ({ deckId, cardId }: { deckId: string; cardId?: string }) => {
  return `${AllPaths.DECKS}/${deckId}/move?cardId=${cardId}`
}

export const toQueryDestinationRoute = (searchParams: URLSearchParams) => {
  const destination = searchParams.get('destination')
  const productId = searchParams.get('product_id')
  const tag = searchParams.get('tag')
  const params = searchParams.toString()

  if (!destination) return

  if (destination && destination !== 'shops' && destination !== 'shop') {
    ErrorNotifier.notify({
      err: new Error('Destination query string wrong'),
      context: {
        key: 'Redirect Destination after logging in is wrong',
      },
    })
  }

  if (productId) return toExternalRoute(`${toShopProductPage(productId)}}?${params}`)
  if (tag) return toExternalRoute(`${toShopSearchPage()}?${params}`)
  return toExternalRoute(`${process.env.NEXT_PUBLIC_WU_SHOP_DOMAIN}?${params}`)
}

export const toMaterialTabPage = ({
  tabName,
  materialId,
}: {
  tabName: string
  materialId: string
}) => {
  return `${AllPaths.DASHBOARD_MATERIALS}/${materialId}/${tabName}`
}

export const toSignInPage = () => {
  return path.join(AllPaths.AUTH, AllPaths.SIGN_IN)
}

export const toSignUpPage = () => {
  return path.join(AllPaths.AUTH, AllPaths.SIGN_UP)
}

export const toProductTabPage = ({ tabName, courseId }: { tabName: string; courseId: string }) => {
  return `${AllPaths.DASHBOARD_PRODUCTS}/${courseId}/edit/${tabName}`
}

export const toEditMissionFormPage = ({ id }: { id: string }) => {
  return path.join(AllPaths.ACCOUNT_CASHBACK_MISSIONS, AllPaths.SUCCESS_FORM, id)
}

export const toCreateMissionFormPage = ({
  missionSlug,
  isSuccess,
}: {
  missionSlug: string
  isSuccess: boolean
}) => {
  return path.join(
    AllPaths.ACCOUNT_CASHBACK_MISSIONS,
    AllPaths.SUCCESS_FORM,
    `new?missionSlug=${missionSlug}&isSuccess=${isSuccess}`,
  )
}

export const toOrderHistoryDetail = ({ orderId }: { orderId: string }) => {
  return `${AllPaths.ACCOUNT_ORDER_HISTORY}/${orderId}`
}

export const toSubscriptionOrderDetail = ({ orderId }: { orderId: string }) => {
  return `${AllPaths.ACCOUNT_SUBSCRIPTION_ORDER_HISTORY}/${orderId}`
}

export const toAddNewCreditCardPage = () => {
  return path.join(AllPaths.ACCOUNT_CREDIT_CARD_MANAGEMENT, 'add-new-card')
}

export const toAddNewAdminCreditCardPage = () => {
  return path.join(AllPaths.DASHBOARD_CREDIT_CARD_MANAGEMENT, 'add-new-card')
}

export const toDeleteAccountPage = () => {
  return AllPaths.ACCOUNT_DELETE
}

export const toEditMaterialComponentGroupsPage = ({
  materialId,
  materialComponentGroupId,
  sectionName,
  imageUrl = '',
  description = '',
}: {
  materialId: string
  materialComponentGroupId: string
  sectionName: string
  imageUrl?: string
  description?: string
}) => {
  return {
    pathname: `${AllPaths.DASHBOARD_MATERIALS}/${materialId}/material-component-groups/${materialComponentGroupId}`,
    query: { sectionName, imageUrl, description },
  }
}

export const toProductCollectionListPage = ({ productId }: { productId: string }) => {
  return `${AllPaths.DASHBOARD_PRODUCTS}/${productId}/edit/collection-offer`
}

export const toProductCollectionEditPage = ({ productId }: { productId: string }) => {
  return {
    pathname: `${AllPaths.DASHBOARD_PRODUCTS}/${productId}/edit/collection-offer-edit`,
  }
}

export const toProductCollectionIdEditPage = ({
  productId,
  collectionOfferId,
}: {
  productId: string
  collectionOfferId: string
}) => {
  return {
    pathname: `${AllPaths.DASHBOARD_PRODUCTS}/${productId}/edit/collection-offer-edit`,
    query: { collectionOfferId },
  }
}

export const toChecklistItemEditPage = ({
  materialId,
  componentId,
  checklistId,
}: {
  materialId: string
  componentId: string
  checklistId: string
}) => {
  return {
    pathname: `${AllPaths.DASHBOARD_MATERIALS}/${materialId}/content/components/${componentId}`,
    query: { checklistId },
  }
}

export const toShopProductTocPage = (productId: string) => {
  return `${process.env.NEXT_PUBLIC_WU_SHOP_DOMAIN}/product/${productId}?tab=toc`
}

export const toShopProductVariantPage = (productId: string) => {
  return `${process.env.NEXT_PUBLIC_WU_SHOP_DOMAIN}/product/${productId}?tab=toc&variants-picker=open&variants-picker-step=variants`
}

export const toSubscriptionFullTestIdPage = ({
  materialId,
  fullTestId,
  examPaperId,
}: {
  materialId: string
  fullTestId: string
  examPaperId?: string
}) => {
  return `/materials/${materialId}${AllPaths.SUBSCRIPTION_DASHBOARD_FULL_TESTS}/${fullTestId}?examPaperId=${examPaperId}`
}

export const toSubscriptionFullTests = ({
  materialId,
  productId,
}: {
  materialId: string
  productId?: string
}) => {
  return appendQueryObject(
    `/materials/${materialId}${AllPaths.SUBSCRIPTION_DASHBOARD_FULL_TESTS}`,
    { productId },
  )
}

export const toSubscriptionWrongQuestions = ({
  materialId,
  productId,
}: {
  materialId: string
  productId?: string
}) => {
  return {
    pathname: `/materials/${materialId}${AllPaths.SUBSCRIPTION_DASHBOARD_WRONG_QUESTIONS}`,
    query: { productId },
  }
}

export const toExpensesIdPage = ({
  expenseId,
  materialId,
}: {
  expenseId: string
  materialId: string
}) => {
  return `${AllPaths.INTERNAL_EXPENSES}/${expenseId}?materialId=${materialId}`
}

export const toInternalShopAdsCreatePage = ({ type, id }: { type: string; id: string }) => {
  return `${AllPaths.INTERNAL_SHOP_ADS_CREATE}/${type}/${id}`
}

export const toCampaignPageCreateWithId = (id: string) => {
  return `${AllPaths.INTERNAL_CAMPAIGNS_CREATE}?pageId=${id}`
}

export const shopProductPage = (materialId: string) =>
  `${process.env.NEXT_PUBLIC_WU_SHOP_DOMAIN}/product/${materialId}`

export const shopReferralPage = ({
  productId,
  referralCode,
}: {
  productId: string
  referralCode: string
}) => `${process.env.NEXT_PUBLIC_WU_SHOP_DOMAIN}/p/${productId}/r/${referralCode}`

export const toShopPostsPage = (postId: string) => {
  return `${process.env.NEXT_PUBLIC_WU_SHOP_DOMAIN}/posts/${postId}`
}
