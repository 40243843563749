import { Button } from '@mui/material'
import { useTranslation } from 'next-i18next'

import { toHome } from '@/routes/helper'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'

import SpaceDumplingImg from '@/components/space-dumpling-img'
import { useRouter } from 'next/navigation'

const NotFound = () => {
  const { t } = useTranslation()
  const router = useRouter()
  const hasLastPage = window.history.length > 2

  const handleBack = () => {
    if (hasLastPage) {
      router.back()
    } else {
      router.replace(toHome())
    }
  }

  return (
    <figcaption className='flex items-center justify-center flex-col w-[90%] mx-auto h-screen'>
      <div className='max-w-[600px] w-full'>
        <SpaceDumplingImg
          variant='NOT_FOUND'
          alt='ufo 404'
          className='w-full object-cover'
        />
      </div>
      <p className='mb-4  text-center'>{t('web.page_not_found.page_title')}</p>

      <Button onClick={handleBack}>
        {hasLastPage ? t('common.back_page') : t('common.back_to_main_page')}
      </Button>
    </figcaption>
  )
}

export default NotFound

export const getStaticProps = async ({ locale }: { locale: string }) => {
  return {
    props: {
      ...(await serverSideTranslations(locale)),
    },
  }
}
